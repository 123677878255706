import Vue from "vue/dist/vue.min";
import domLoaded from "../../../js/_helpers/domLoaded";

const PageBanner = () => import(/* webpackChunkName: "PageBanner" */ "./PageBanner.vue");

function init() {
    const pageBanners = document.querySelectorAll("[data-vue='page-banner']");

    if (!pageBanners) return;

    pageBanners.forEach((el) => {
        return new Vue({
            el,
            components: { PageBanner },
        });
    });
}

domLoaded(init);
